var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.effortStep.title")))])])])]),_vm._l((_vm.form.efforts),function(effort,index){return _c('div',{key:index,staticClass:"suppEntry"},[_c('h4',{staticClass:"level1 nextIsTitle"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.effort") + " " + (index + 1))+" ")]),_c('b-overlay',{key:'effort' + index + effort['closeDatetime'],staticClass:"closeOverley",attrs:{"show":effort['closeDatetime'] !== undefined,"rounded":"sm","z-index":"100","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.$showDate(effort["closeDatetime"]))+" ")])]},proxy:true}],null,true)},[_c('fieldset',[_c('h4',{staticClass:"level2"},[_vm._v(_vm._s(_vm.$t("editTrip.effortStep.fishingInfos")))]),(_vm.licencesOptions.length > 2)?_c('Select',{attrs:{"label":_vm.$t('editTrip.effortStep.licenceNumber'),"options":_vm.licencesOptions,"required":true},model:{value:(effort.licenceNumber),callback:function ($$v) {_vm.$set(effort, "licenceNumber", $$v)},expression:"effort.licenceNumber"}}):_vm._e(),_c('FishingInfoDisplay',{attrs:{"licence":_vm.selectedLicence(effort.licenceNumber),"displayLicenceNumber":_vm.licencesOptions.length === 2,"effort":effort,"index":index,"addErrorToParent":error => {
              _vm.addErrors(error, index);
            }}})],1),_c('BeginEffort',{attrs:{"effort":effort,"addErrorToParent":error => {
            _vm.addErrors(error, index);
          },"showErrorData":_vm.showErrorData + _vm.refreshIndex[index],"form":_vm.form,"index":index}}),_c('fieldset',{attrs:{"disabled":effort['closeDatetime'] !== undefined}},[_c('FishingGears',{attrs:{"effort":effort,"addErrorToParent":error => {
              _vm.addErrors(error, index);
            },"showErrorData":_vm.showErrorData + _vm.refreshIndex[index],"index":index}}),_c('Tow',{attrs:{"effort":effort,"addErrorToParent":error => {
              _vm.addErrors(error, index);
            },"showErrorData":_vm.showErrorData + _vm.refreshIndex[index],"form":_vm.form,"index":index}}),_c('EndEffort',{attrs:{"effort":effort,"addErrorToParent":error => {
              _vm.addErrors(error, index);
            },"showErrorData":_vm.showErrorData + _vm.refreshIndex[index],"form":_vm.form,"index":index}})],1),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeBottomPadding"},[_c('div',[(!effort['closeDatetime'])?_c('input',{staticClass:"closeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.effortStep.closeEffort') + String(index + 1)},on:{"click":function($event){return _vm.closeEffort(index)}}}):_vm._e()])])],1)],1)}),_c('div',{staticClass:"suppEntryButtonWrapper"},[_c('div',[(
          _vm.form.efforts &&
            _vm.form.efforts.length > 0 &&
            !_vm.$isLastItemClosed(_vm.form.efforts)
        )?_c('input',{staticClass:"removeSuppEntryButton removeTopMargin",attrs:{"type":"button","value":_vm.$t('editTrip.effortStep.deleteEffortButton') +
            String(_vm.form.efforts.length)},on:{"click":_vm.deleteEffort}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.effortStep.addEffort')},on:{"click":_vm.addEffort}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }