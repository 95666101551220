<template>
  <div class="page-main">
    <h1>{{ $t("connexion.register") }}</h1>

    <p class="noAccount">
      {{ $t("connexion.registered") }}
      <router-link :to="{ name: 'connexion' }">
        {{ $t("connexion.connect") }}
      </router-link>
    </p>

    <form id="signup_form" method="POST" @submit="validate">
      <fieldset>
        <GenericInput
          :help="$t('fin.desc')"
          :label="$t('fin.label')"
          v-model="fin"
          type="text"
          :required="true"
          :finValidator="true"
          @error="checkError"
          :refeshError="refeshError"
        />
        <br />
        <GenericInput
          :label="$t('fin.confirm')"
          v-model="fin_confirm"
          type="text"
          :required="true"
          :forceErrorMsg="finConfirmError"
          @error="checkError"
          :refeshError="refeshError"
          @binding="checkFinConfirmation"
        />
        <br />
        <Captcha
          v-model="captcha"
          :captcha="captcha"
          :refresh="refreshCaptcha"
          @refreshed="refreshedCaptcha"
          @error="checkError"
          :refeshError="refeshError"
        />
      </fieldset>
      <button class="primaryAction" type="submit">
        {{ $t("next") }}
      </button>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Captcha from "@/components/Captcha.vue";
import DB from "@/service/db";

export default {
  name: "connexion-signup",
  computed: {},
  components: {
    GenericInput,
    Captcha
  },
  data() {
    return {
      fin: "",
      fin_confirm: "",
      captcha: {
        text: null,
        id: null
      },
      refreshCaptcha: false,
      refeshError: false,
      finConfirmError: false,
      error: {}
    };
  },
  methods: {
    ...mapActions("api", ["signup"]),
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshedCaptcha() {
      this.refreshCaptcha = false;
    },
    refreshForm() {
      this.refeshError = !this.refeshError;
    },
    checkFinConfirmation(e) {
      const hasFin = this.fin && this.fin.length > 0;
      const hasConfirmation = this.fin_confirm && this.fin_confirm.length > 0;
      if (hasFin && hasConfirmation && this.fin != this.fin_confirm) {
        this.finConfirmError = this.$i18n.t("validator.unconfirmed");
        this.refeshError = !this.refeshError;
      }
    },
    validate: function(e) {
      e.preventDefault();
      const vuejs = this;

      this.refeshError = !this.refeshError;
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }

        const form = { fin: vuejs.fin, captcha: vuejs.captcha };
        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(() => DB.setItem("signup", form))
          .then(() => vuejs.signup(form))
          .then(() =>
            vuejs.$router
              .replace({ name: "connexionTermsAndConditions" })
              .catch(error => {
                // ignore because of exception on navigation guard...
              })
          )
          .catch(error => {
            this.refreshCaptcha = true;
            if (error.errorType == "user") {
              if (error?.errors?.hasOwnProperty("captcha_text")) {
                vuejs.$store.dispatch(
                  "alert/error",
                  error.errors["captcha_text"]
                );
              } else {
                return vuejs.$router
                  .replace({ name: "connexionsignupfail" })
                  .catch(error => {
                    // ignore because of exception on navigation guard...
                  });
              }
            } else {
              vuejs.$alertUserApiError(error);
            }
          })
          .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
      });
    }
  },
  watch: {
    fin_confirm(newVal) {
      this.finConfirmError = false;
    }
  }
};
</script>
