import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";
import DB from "@/service/db";
import alert from "@/store/alert.module";
import editTrip from "@/store/editTrip.module";
import editTripSubform from "@/store/editTripSubform.module";
import api from "@/store/api.module";

const dateFormat = require("dateformat");

Vue.use(Vuex);

function updateDateFormatNames(language) {
  if (language == "fr") {
    dateFormat.i18n.monthNames = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jul",
      "Aou",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ];
  } else {
    dateFormat.i18n.monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
  }
}

function initAppData(context) {
  return _getUserProfile(context)
    .then(() => _refreshSystemList(context))
    .then(() => _refreshSubscriptionData(context))
    .catch(error => {
      console.error("Error in initAppData", error); // eslint-disable-line no-console
      throw error;
    });
}

function _refreshSubscriptionData(context) {
  const storeRefGetKey = "SUBSCRIPTIONS_REF";
  const storeRefSetKey = "SET_SUBSCRIPTION_REF";
  const serialRefKey = "subscriptionRef";

  return context
    .dispatch("api/getSubscriptions")
    .then(function(data) {
      return DB.setSubscriptions(data);
    })
    .catch(error => {
      console.error("Error while getting subscriptions", error); // eslint-disable-line no-console
    })
    .then(function() {
      return _loadDbValue(serialRefKey, storeRefSetKey, context).then(function(
        subscriptionRef
      ) {
        const uuids = context.getters.SUBSCRIPTIONS_REF;

        const operations = new Array();
        for (let index = 0; index < uuids.length; index++) {
          const uuid = uuids[index];
          operations.push(DB.getItem(`subscription_${uuid}`));
        }
        return Promise.all(operations).then(function(subscriptions) {
          if (process.env.VUE_APP_DEBUG_PROMISE)
            console.debug("! loadSubscriptions", subscriptions); // eslint-disable-line no-console
          context.commit("SET_SUBSCRIPTIONS", subscriptions);

          // refresh current subscription if already one selected
          if (context.getters.SUBSCRIPTION) {
            const uuid = context.getters.SUBSCRIPTION.uuid;
            context.commit("SET_CURRENT_SUBSCRIPTION", uuid);
          }
          Promise.resolve(subscriptions);
        });
      });
    });
}

function _manageErrorInHashCheck(error) {
  console.error("No hash", error); // eslint-disable-line no-console
  // if remote hash verification fails and we have data, skip refresh
  if (error && error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
    return Promise.resolve(false);
  }

  // if no data available, we can skip the hash check and make the data sync call
  return Promise.resolve(true);
}

function _getUserProfile(context) {
  // no hash on user profile because of vessel and operators
  // TODO: find another way (taking them out? dont seem winning)
  const serialKey = "userProfile";
  const storeSetKey = "SET_USER_PROFILE";
  return context
    .dispatch("api/getUserProfile")
    .then(data => DB.setItem(serialKey, data))
    .then(() => DB.setItem("isClientAuthenticatable", true))
    .catch(function(error) {
      console.error("Network issue? keep going", error); // eslint-disable-line no-console
      if (error.errorType == "session") {
        // if the app could never authentify, invalidates the browser being used
        DB.getItem("isClientAuthenticatable").then(isClientAuthenticatable => {
          if (isClientAuthenticatable === null) {
            DB.setItem("isClientAuthenticatable", false);
          }
        });
      }
    })
    .then(function() {
      return _loadDbValue(serialKey, storeSetKey, context);
    });
}

function _refreshSystemList(context) {
  const storeSetKey = "SET_USER_SYSTEM_LISTS";
  const serialKey = "systemsLists";
  const serialHashKey = "systemsListsKey";

  const handleSaveFromRemoteToDB = function(data) {
    return DB.setItem(serialKey, data).then(function() {
      return DB.setItem(serialHashKey, data.updated);
    });
  };
  const handleLoadFromDBToVuex = function() {
    return _loadDbValue(serialKey, storeSetKey, context);
  };

  return DB.getItem(serialHashKey)
    .then(function(hash) {
      return context.dispatch("api/getSystemsLists", hash);
    })
    .then(handleSaveFromRemoteToDB)
    .catch(function(error) {
      // catch update failures in order to load what we have
    })
    .then(handleLoadFromDBToVuex);
}

function _loadDbValue(dbKey, storeKey, context) {
  if (process.env.VUE_APP_DEBUG_PROMISE) console.debug("storeKey =", storeKey); // eslint-disable-line no-console
  return DB.getItem(dbKey).then(function(value) {
    if (value) context.commit(storeKey, value);
    if (process.env.VUE_APP_DEBUG_PROMISE) console.debug("value =", value); // eslint-disable-line no-console
    return Promise.resolve(value);
  });
}

function subscriptionWillExpireSoon(expiration) {
  if (expiration === null || expiration === undefined) return;
  let expDate = expiration;
  if (typeof expDate.getMonth !== "function") {
    expDate = new Date(expiration);
  }

  if (typeof expDate.getMonth === "function") {
    const now = new Date();
    const expTimestamp = expDate.getTime();
    const targetTimestamp = now.getTime();
    const renewOffset = 90 * 24 * 60 * 60 * 1000; // 90 days
    if (
      expTimestamp > targetTimestamp &&
      expTimestamp < targetTimestamp + renewOffset
    ) {
      return true;
    }
  }
  return false;
}

const store = new Vuex.Store({
  modules: {
    alert,
    api,
    editTrip,
    editTripSubform
  },
  state: {
    appInitialized: false,
    appLocked: false,
    isAppLoading: false,
    language: i18n.locale,

    // from database
    offlineToken: null,
    csrfToken: null, // from cookie
    userProfile: null,

    versionHash: null,

    subscriptionRef: null,
    subscriptionsHash: null,
    subscriptions: [],
    currentSubscription: null,

    systemsLists: null,

    currentTripList: [], // synched trips
    currentOpenTrip: {},
    currentClosedTrip: {},
    selectedTrip: {},
    closedTrips: [],

    buildIdRequired: false,
    DFOPrivacyNoticeStatement: null,

    //online status
    appOnline: navigator.onLine,
    appWithInternet: navigator.onLine,
    timeOutError: false,
    onlineAccessRequired: true,
    editTripActivated: false,
    needRefreshWindow: false,

    //Integer input validation with browser name
    needBrowserNumberValidation:
      navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
      (navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
        navigator.userAgent.toLowerCase().indexOf("chrome") == -1)
  },
  getters: {
    GET_NEED_REFRESH_WINDOW: state => {
      return state.needRefreshWindow;
    },
    GET_IN_EDIT_TRIP: state => {
      return state.editTripActivated;
    },
    IS_ONLINE_ACCESS_REQUIRED: state => {
      return state.onlineAccessRequired;
    },
    IS_NEED_BROWSER_NUMBER_VALIDATION: state => {
      return state.needBrowserNumberValidation;
    },
    IS_APP_CONNECTED_ONLINE: state => {
      if (process.env.VUE_APP_DEBUG_ONLINE)
        // eslint-disable-next-line no-console
        console.log(
          "IS_APP_CONNECTED_ONLINE " +
            (state.appOnline && state.appWithInternet && !state.timeOutError)
        );
      return state.appOnline && state.appWithInternet && !state.timeOutError;
    },
    IS_APP_ONLINE: state => {
      return state.appOnline;
    },
    IS_APP_WITH_INTERNET: state => {
      return state.appWithInternet;
    },
    IS_TIMEOUTERROR: state => {
      return state.timeOutError;
    },
    GET_CURRENT_ONLINE_ERROR: state => {
      if (state.timeOutError) {
        return i18n.t("message.timeOutError");
      } else if (!state.appWithInternet) {
        return i18n.t("message.noInternetError");
      } else {
        return i18n.t("message.badInternetError");
      }
      return false;
    },
    IS_APP_INITIALIZED: state => {
      return state.appInitialized;
    },
    IS_AUTHENTICATED: state => {
      return state.appInitialized && state.offlineToken != null;
    },
    IS_APP_LOCKED: state => {
      return state.appLocked;
    },
    LANGUAGE: state => {
      //should always be same as i18n.locale
      return state.language;
    },
    OFFLINETOKEN: state => {
      return state.offlineToken;
    },
    CSRFTOKEN: state => {
      return state.csrfToken;
    },
    VERSION_HASH: state => {
      return state.versionHash;
    },
    SUBSCRIPTIONS_HASH: state => {
      return state.subscriptionsHash;
    },
    SUBSCRIPTIONS: state => {
      return state.subscriptions;
    },
    SUBSCRIPTIONS_REF: state => {
      return state.subscriptionRef;
    },
    SUBSCRIPTION: state => {
      return state.currentSubscription;
    },
    TRIP_LIST: state => {
      return state.currentTripList;
    },
    USER_PROFILE: state => {
      return state.userProfile;
    },
    SYSTEMLISTS: state => {
      return state.systemsLists;
    },
    SELECTED_TRIP: state => {
      return state.selectedTrip;
    },
    OPEN_TRIP: state => {
      return state.currentOpenTrip;
    },
    CLOSED_TRIPS: state => {
      return state.closedTrips;
    },
    IS_BUILDID_REQUIRED: state => {
      return state.buildIdRequired;
    }
  },
  mutations: {
    RESET_STORE: (state, payload) => {
      console.warn("reseting store"); // eslint-disable-line no-console
      state.offlineToken = null;
      state.csrfToken = null;
      state.userProfile = null;
      state.versionHash = null;
      state.subscriptionRef = null;
      state.subscriptionRef = null;
      state.subscriptionsHash = null;
      state.subscriptions = [];
      state.currentSubscription = null;
      state.systemsLists = null;
      state.currentTripList = [];
      state.currentOpenTrip = {};
      state.currentClosedTrip = {};
      state.selectedTrip = {};
      state.closedTrips = [];
    },
    SET_NEED_REFRESH_WINDOW: (state, activated) => {
      state.needRefreshWindow = activated;
    },
    SET_IN_EDIT_TRIP: (state, activated) => {
      state.editTripActivated = activated;
    },
    SET_ONLINE_ACCESS_REQUIRED: (state, online) => {
      state.onlineAccessRequired = online;
    },
    SET_INTERNET: (state, activated) => {
      state.appWithInternet = activated;
    },
    SET_ONLINE: (state, activated) => {
      state.appOnline = activated;
    },
    SET_TIMEOUTERROR: (state, activated) => {
      state.timeOutError = activated;
    },
    SET_INITIALIZED: (state, initialized) => {
      state.appInitialized = initialized;
    },
    SET_LOCK: (state, lock) => {
      state.appLocked = lock;
    },
    SET_APP_LOADING: (state, loading) => {
      state.isAppLoading = loading;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    },
    SET_SUBSCRIPTION_REF: (state, payload) => {
      state.subscriptionRef = payload;
    },
    SET_SUBSCRIPTIONS: (state, payload) => {
      state.subscriptions.splice(0, state.subscriptions.length);
      for (let index = 0; index < payload.length; index++) {
        let subscription = payload[index];
        subscription.willExpire = subscriptionWillExpireSoon(
          subscription.expiration
        );
        state.subscriptions.push(subscription);
      }
    },
    ADD_SUBSCRIPTION: (state, payload) => {
      payload.willExpire = subscriptionWillExpireSoon(payload.expiration);
      state.subscriptions.push(payload);
    },
    RESET_TRIPLIST: (state, payload) => {
      state.currentTripList = [];
      // state.currentTripList.splice(0, state.subscriptions.length);
    },
    UPDATE_TRIP: (state, payload) => {
      const index = payload.index;
      const trip = payload.trip;
      state.currentTripList[index] = trip;
    },
    PUSH_TRIP: (state, payload) => {
      state.currentTripList.push(payload);
    },
    INSERT_TRIP: (state, payload) => {
      state.currentTripList.unshift(payload);
    },
    SET_SELECTED_TRIP: (state, payload) => {
      state.selectedTrip = payload;
    },
    SET_CURRENT_OPEN_TRIP: (state, payload) => {
      state.currentOpenTrip = payload;
    },
    SET_CURRENT_CLOSED_TRIP: (state, payload) => {
      let trip = "";
      for (let i in state.closedTrips) {
        if (
          state.closedTrips[i]["logbookUID"] === payload ||
          state.closedTrips[i]["reportUID"] === payload
        ) {
          trip = state.closedTrips[i];
          break;
        }
      }

      // Idiom to trigger watchers
      state.currentClosedTrip = JSON.parse(JSON.stringify(trip));
    },
    SET_BUILDID_REQUIRED: (state, payload) => {
      state.buildIdRequired = payload;
    },
    SET_CLOSED_TRIPS: (state, payload) => {
      // state.closedTrips = JSON.parse(JSON.stringify(payload));
      state.closedTrips = payload;
    },
    SET_CURRENT_SUBSCRIPTION: (state, payload) => {
      const index = state.subscriptionRef.indexOf(payload);
      state.currentSubscription = state.subscriptions[index];
    },
    SET_OFFLINETOKEN: (state, payload) => {
      state.offlineToken = payload;
    },
    SET_CSRFTOKEN: (state, payload) => {
      state.csrfToken = payload;
    },
    SET_USER_PROFILE: (state, payload) => {
      state.userProfile = payload;
    },
    SET_USER_ELOGKEY: (state, payload) => {
      state.userProfile.hasElogKey = payload;
    },
    SET_USER_SUBSCRIPTION_HASH: (state, payload) => {
      state.subscriptionsHash = payload;
    },
    SET_USER_SYSTEM_LISTS: (state, payload) => {
      state.systemsLists = payload;
    },
    SET_USER_VERSION_HASH: (state, payload) => {
      state.versionHash = payload;
    }
  },
  actions: {
    checkAuthentification(context) {
      if (context.getters.IS_AUTHENTICATED) return Promise.resolve(true);
      if (context.getters.IS_APP_LOCKED)
        return Promise.reject(new Error("Lock n load"));

      context.commit("SET_APP_LOADING", true);
      return DB.getTokens()
        .then(function(tokens) {
          context.commit("SET_OFFLINETOKEN", tokens.offlineToken);
          context.commit("SET_CSRFTOKEN", tokens.csrfToken);
          // return Promise.resolve(true);
          return context.dispatch("api/setToken", tokens.offlineToken);
        })
        .then(function() {
          return initAppData(context);
        })
        .then(function() {
          context.commit("SET_INITIALIZED", true);
          return Promise.resolve(true);
        })
        .finally(() => {
          context.commit("SET_APP_LOADING", false);
        });
    },
    logIn(context, { username, password }) {
      context.commit("SET_APP_LOADING", true);
      return context
        .dispatch("api/login", { username: username, password: password })
        .then(function(data) {
          if (process.env.VUE_APP_DEBUG_PROMISE)
            console.debug("- SET_OFFLINETOKEN", data); // eslint-disable-line no-console
          context.commit("SET_OFFLINETOKEN", data.offlineToken);
          context.commit("SET_CSRFTOKEN", data.csrfToken);
          return DB.setSpecialK(password, data.offlineToken, data.csrfToken);
        })
        .finally(function() {
          context.commit("SET_APP_LOADING", false);
        });
    },
    updateSpecialK(context, password) {
      if (context.getters.OFFLINETOKEN == null) {
        return DB.getTokens().then(function(tokens) {
          return DB.setSpecialK(
            password,
            tokens.offlineToken,
            tokens.csrfToken
          );
        });
      } else {
        return DB.setSpecialK(
          password,
          context.getters.OFFLINETOKEN,
          context.getters.CSRFTOKEN
        );
      }
    },
    lock(context) {
      context.commit("SET_LOCK", true);
      context.commit("RESET_STORE", true);
      return DB.lock();
    },
    unlock(context, password) {
      return DB.unlock(password).then(function(success) {
        if (success) {
          context.commit("SET_LOCK", false);
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      });
    },
    delete(context) {
      context.commit("SET_INITIALIZED", false);
      context.commit("SET_LOCK", false);
      context.dispatch("api/logout");
      return DB.delete();
    },
    lostSession(context) {
      // when session is invalidated by the server, the user is not authenticated anymore
      // yet he could have open and closed trips, which we should not delete as he probably will log back in
      context.commit("SET_INITIALIZED", false);
      context.commit("SET_LOCK", false);
      context.commit("RESET_STORE", true);
      return DB.logout();
    },
    dataReinitialization(context) {
      context.commit("SET_APP_LOADING", true);
      context.commit("SET_INITIALIZED", false);
      return initAppData(context)
        .then(function() {
          context.commit("SET_INITIALIZED", true);
          return Promise.resolve(true);
        })
        .finally(() => {
          context.commit("SET_APP_LOADING", false);
        });
    },
    loadRemoteForms(context, uuid = null) {
      if (!uuid) {
        uuid = context.getters.SUBSCRIPTION.uuid;
      }
      const addForms = function(trips) {
        context.commit("RESET_TRIPLIST");
        for (let trip of trips || []) {
          context.commit("PUSH_TRIP", trip);
        }
      };
      return DB.getItem(`synchedTrips_${uuid}`)
        .then(addForms)
        .catch(error => {
          console.error("Error loading remote forms", error); // eslint-disable-line no-console
        });
    },
    getRemoteForms(context, uuid) {
      return DB.getItem(`synchedHash_${uuid}`)
        .then(hash =>
          context.dispatch("api/getInactivities", {
            id: uuid,
            lastmodifiedtimestamp: hash
          })
        )
        .then(function(data) {
          const triplist = context.getters.TRIP_LIST;
          const closedForms = context.getters.CLOSED_TRIPS;
          // maintain server ordering
          for (let i = data.inactivities.length - 1; i >= 0; i--) {
            const trip = data.inactivities[i];
            const index = triplist.findIndex(
              x => x.reportUID == trip.reportUID
            );
            if (index >= 0) {
              context.commit("UPDATE_TRIP", { index: index, trip: trip });
            } else {
              context.commit("INSERT_TRIP", trip);
            }
          }

          if (data.nextqueryseconds > 0) {
            setTimeout(function() {
              console.info("getRemoteForms after ", data.nextqueryseconds, "s"); // eslint-disable-line no-console
              context.dispatch("getRemoteForms", uuid);
            }, data.nextqueryseconds * 1000);
          }

          return DB.setItem(
            `synchedHash_${uuid}`,
            data.lastmodifiedtimestamp
          ).then(function() {
            return DB.setItem(
              `synchedTrips_${uuid}`,
              context.getters.TRIP_LIST
            );
          });
        })
        .then(() => context.dispatch("cleanHangingClosedForm", uuid));
    },
    updateTripList(context) {
      const uuid = context.getters.SUBSCRIPTION.uuid;
      return DB.getItem(`synchedHash_${uuid}`)
        .then(hash =>
          context.dispatch("api/getTrips", {
            subscriptionUUID: uuid,
            lastmodifiedtimestamp: hash
          })
        )
        .then(function(data) {
          // TODO: what to do with these in gettriplist? hasSubscriptionIssue needReview
          const triplist = context.getters.TRIP_LIST;
          // maintain server ordering
          for (let i = data.trips.length - 1; i >= 0; i--) {
            const trip = data.trips[i];
            const index = triplist.findIndex(
              x => x.logbookUID == trip.logbookUID
            );
            if (index >= 0) {
              context.commit("UPDATE_TRIP", { index: index, trip: trip });
            } else {
              context.commit("INSERT_TRIP", trip);
            }
          }

          if (data.nextqueryseconds > 0) {
            setTimeout(function() {
              console.info("Calling again after", data.nextqueryseconds, "s"); // eslint-disable-line no-console
              context.dispatch("updateTripList");
            }, data.nextqueryseconds * 1000);
          }

          return DB.setItem(
            `synchedHash_${uuid}`,
            data.lastmodifiedtimestamp
          ).then(function() {
            return DB.setItem(
              `synchedTrips_${uuid}`,
              context.getters.TRIP_LIST
            );
          });
        })
        .then(() => context.dispatch("cleanHangingClosedForm", uuid));
    },
    async selectClosedTrips(context, uuid) {
      try {
        let closedTrips = await DB.getItem(`closedTrips_${uuid}`);

        if (closedTrips === null) {
          closedTrips = [];
        }
        context.commit("SET_CLOSED_TRIPS", closedTrips);
      } catch (err) {
        context.dispatch("alert/error", err);
      }
    },
    cleanHangingClosedForm(context, uuid) {
      // clean remnant closed form that may have survived
      const closedForms = context.getters.CLOSED_TRIPS;
      const syncTrips = context.getters.TRIP_LIST;

      let result = closedForms.filter(closedTrip => {
        const index = syncTrips.findIndex(synchedTrip => {
          if (synchedTrip.hasOwnProperty("logbookUID")) {
            return synchedTrip.logbookUID == closedTrip.logbookUID;
          } else {
            return synchedTrip.reportUID == closedTrip.reportUID;
          }
        });

        if (index >= 0) {
          return false;
        }
        return true;
      });

      if (result.length != syncTrips.length) {
        context.commit("SET_CLOSED_TRIPS", result);
        return DB.setItem(`closedTrips_${uuid}`, result);
      }

      return Promise.resolve(true);
    },
    async deleteCurrentClosedTrip(context, payload) {
      try {
        let trips = await DB.getItem(`closedTrips_${payload.uuid}`);
        let uidType = payload.form["logbookUID"] ? "logbookUID" : "reportUID";
        let newTrip = true;
        for (let i in trips) {
          if (trips[i][uidType] === payload.form[uidType]) {
            trips.splice(i, 1);
            break;
          }
        }

        await DB.setItem(`closedTrips_${payload.uuid}`, trips);
        context.dispatch("selectClosedTrips", payload.uuid);
      } catch (err) {
        context.dispatch("alert/error", err);
      }
    },
    async setCurrentClosedTrip(context, payload) {
      try {
        let trips = context.state.closedTrips;
        let uidType = payload.currentClosedTrip["logbookUID"]
          ? "logbookUID"
          : "reportUID";
        let newTrip = true;
        for (let i in trips) {
          if (trips[i][uidType] === payload.currentClosedTrip[uidType]) {
            trips[i] = payload.currentClosedTrip;
            newTrip = false;
            break;
          }
        }

        if (newTrip) {
          trips.push(payload.currentClosedTrip);
        }

        await DB.setItem(`closedTrips_${payload.uuid}`, trips);
        context.dispatch("selectClosedTrips", payload.uuid);
        context.commit(
          "SET_CURRENT_CLOSED_TRIP",
          payload.currentClosedTrip[uidType]
        );
      } catch (err) {
        context.dispatch("alert/error", err);
      }
    },
    async setCurrentOpenTrip(context, payload) {
      try {
        await DB.setItem(`openTrip_${payload.uuid}`, payload.currentOpenTrip);
        await context.dispatch("selectCurrentOpenTrip", payload.uuid);
      } catch (err) {
        context.dispatch("alert/error", err);
      }
    },
    async selectCurrentOpenTrip(context, uuid) {
      try {
        let currentOpenTrip = await DB.getItem(`openTrip_${uuid}`);
        if (currentOpenTrip === null) {
          currentOpenTrip = {};
        }
        context.commit("SET_CURRENT_OPEN_TRIP", currentOpenTrip);
      } catch (err) {
        context.dispatch("alert/error", err);
      }
    },
    selectTrip(context, luid) {
      return new Promise(function(resolve, reject) {
        const openTrip = context.getters.OPEN_TRIP;
        const isOpenTrip =
          openTrip &&
          (openTrip["logbookUID"] === luid || openTrip["reportUID"] === luid);

        if (isOpenTrip) {
          context.commit("SET_SELECTED_TRIP", openTrip);
          resolve(true);
        } else {
          const closedTrips = context.getters.CLOSED_TRIPS;
          let trip = null;
          for (let index = 0; index < closedTrips.length && !trip; index++) {
            const t = closedTrips[index];
            if (t["logbookUID"] == luid || t["reportUID"] === luid) {
              trip = t;
            }
          }
          if (trip) {
            context.commit("SET_SELECTED_TRIP", trip);
            resolve(true);
          } else {
            const syncTrips = context.getters.TRIP_LIST;
            trip = null;
            for (let index = 0; index < syncTrips.length && !trip; index++) {
              const t = syncTrips[index];
              if (t["logbookUID"] == luid || t["reportUID"] === luid) {
                trip = t;
              }
            }
            if (trip) {
              context.commit("SET_SELECTED_TRIP", trip);
              resolve(true);
            } else {
              context.dispatch("alert/error", i18n.t("message.no_trip"));
              reject(i18n.t("message.no_trip"));
            }
          }
        }
      });
    },
    selectArchive(context, trip) {
      context.commit("SET_SELECTED_TRIP", trip);
    },
    selectReportContext(context, id) {
      context.commit("SET_CURRENT_OPEN_TRIP", {});
      context.commit("SET_CLOSED_TRIPS", []);
      context.commit("RESET_TRIPLIST");

      // load local trips (open/close)
      return Promise.all([
        _loadDbValue(`openTrip_${id}`, "SET_CURRENT_OPEN_TRIP", context),
        _loadDbValue(`closedTrips_${id}`, "SET_CLOSED_TRIPS", context),
        context.dispatch("loadRemoteForms", id)
      ]);
    },
    selectSubscription(context, uuid) {
      context.commit("SET_CURRENT_SUBSCRIPTION", uuid);
      context.commit("SET_CURRENT_OPEN_TRIP", {});
      context.commit("SET_CLOSED_TRIPS", []);
      context.commit("RESET_TRIPLIST");

      // load local trips (open/close)
      return Promise.all([
        _loadDbValue(`openTrip_${uuid}`, "SET_CURRENT_OPEN_TRIP", context),
        _loadDbValue(`closedTrips_${uuid}`, "SET_CLOSED_TRIPS", context),
        context.dispatch("loadRemoteForms")
      ]);
    },
    setLanguage(context, language) {
      i18n.locale = language;
      updateDateFormatNames(language);
      context.commit("SET_LANGUAGE", language);
      document.documentElement.lang = language;
      return DB.setItem("locale", language);
    },
    saveUserProfile(context, newProfile) {
      const save = function(data) {
        return DB.setItem("userProfile", data);
      };
      const refreshState = function() {
        return _loadDbValue("userProfile", "SET_USER_PROFILE", context);
      };

      context.commit("SET_APP_LOADING", true);
      return context
        .dispatch("api/editUserProfile", newProfile)
        .then(save)
        .then(refreshState)
        .finally(function() {
          context.commit("SET_APP_LOADING", false);
        });
    },
    updateElogKey(context, elogkey) {
      return context.dispatch("api/editElogKey", elogkey).then(() => {
        context.commit("SET_USER_ELOGKEY", true);
      });
    },
    saveSubscriptionSettings: function(context, settings) {
      const subscription = context.getters.SUBSCRIPTION;
      const uuid = subscription.uuid;
      return context
        .dispatch("api/editModuleSettings", settings)
        .then(() => _refreshSubscriptionData(context))
        .then(() => context.commit("SET_CURRENT_SUBSCRIPTION", uuid));
    },
    updateSubscriptions(context) {
      return _refreshSubscriptionData(context);
    },
    sendTrip(context, payload) {
      let trip = payload.trip;
      if (!trip.transmissions) {
        trip.transmissions = [];
      }
      context.commit("SET_APP_LOADING", true);
      let uuid, promise;
      if (payload.type) {
        uuid = payload.type;
        promise = context.dispatch("api/pushInactivity", {
          uuid: uuid,
          form: trip
        });
      } else {
        const subscription = context.getters.SUBSCRIPTION;
        uuid = subscription.uuid;
        promise = context.dispatch("api/pushTrip", {
          uuid: uuid,
          form: trip
        });
      }
      return (
        promise
          .then(function() {
            context.dispatch("alert/success", i18n.t("message.send_success"));
            return Promise.resolve(true);
          })
          // LF: here we just created the trip on the server which means
          // at the next getTrips we will receive it in the synched trips
          // we use to perform cleanup here but is now done in cleanHangingClosedForm
          // TODO:  check if we need to put back SET_SELECTED_TRIP null
          .catch(function(error) {
            let message = "";

            if (error?.code) {
              message += `[${error.code}] `;
            }

            if (error.hasOwnProperty("i18n")) {
              message += i18n.t(error.i18n);
            } else if (error.hasOwnProperty("message")) {
              // axios message if no details in Jobel response
              message += error.message;
            } else if (error.hasOwnProperty("errors")) {
              // axios message if no details in Jobel response
              let messages = [];
              const keys = Object.keys(error.errors);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = error.errors[key][0];
                messages.push(`[${key}]: ${value}`);
              }
              message += messages.join("\n\n");
            } else {
              // if this happens then service/api interceptor is not working properly
              console.error("push error without message", error); // eslint-disable-line no-console
            }

            trip.transmissions.push({
              status: false,
              date: new Date().toISOString(),
              message: message
            });

            // TODO: setCurrentClosedTrip seems unneeded here since we dont delete the closed trips until it is in synchedtrips
            context.dispatch("setCurrentClosedTrip", {
              uuid: uuid,
              currentClosedTrip: trip
            });
            context.dispatch("alert/error", i18n.t("message.send_trip_error"));
            return;
          })
          .finally(function() {
            context.commit("SET_APP_LOADING", false);
            return Promise.resolve(true);
          })
      );
    },
    showLoadingOverlay(context) {
      return Promise.resolve(context.commit("SET_APP_LOADING", true));
    },
    hideLoadingOverlay(context) {
      return Promise.resolve(context.commit("SET_APP_LOADING", false));
    },
    getSystemsLists(context) {
      return _refreshSystemList(context);
    }
  }
});

export default store;
