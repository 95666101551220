<template>
  <div id="app">
    <div class="loadingOverlay" v-show="isLoading">
      <div><img class="wheel" :src="logoWheel" /></div>
    </div>
    <CheckTnC />
    <b-row no-gutters>
      <b-col>
        <Header />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <OnlineError />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <router-view />
      </b-col>
    </b-row>
    <Footer />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import OnlineError from "@/components/OnlineError.vue";
import logoWheel from "@/assets/loader.gif";
import CheckTnC from "@/components/CheckTnC.vue";

export default {
  components: {
    Header,
    Footer,
    OnlineError,
    CheckTnC
  },
  data() {
    return {
      logoWheel
    };
  },
  computed: {
    ...mapGetters("api", ["isAuthenticated"]),
    ...mapState({
      alert: state => state.alert,
      subscription: state => state.currentSubscription,
      alertToggle: state => state.alert.toggle,
      isAuthenticated: state => state.offlineToken,
      isLoading: state => state.isAppLoading
    })
  },
  watch: {
    alertToggle() {
      this.$swal({
        title: this.alert.message,
        icon: this.alert.type,
        button: "OK"
      });
    },
    subscription() {
      this.addDemoClass();
    },
    isAuthenticated() {
      if (!this.isAuthenticated) {
        // may be we need to use checkAuthentification ?
        // or it may be that it is now redundant
        if (this.$route?.meta?.requiresAuth) {
          this.$swal({
            title: this.$t("error"),
            text: this.$t("connexion.disconnected"),
            icon: "error",
            button: this.$t("connexion.login")
          });
          this.$router.push({ name: "connexion" });
        }
      }
    },
    $route: {
      handler: function() {
        this.addDemoClass();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addDemoClass() {
      if (
        this.subscription &&
        this.subscription.module &&
        this.subscription.module.isDemo &&
        this.$route.meta.canHaveDemoBackground
      ) {
        document.body.classList.add("demo");
      } else {
        document.body.classList.remove("demo");
      }
    }
  },
  beforeCreate() {
    // Nothing gets called before me!
    String.prototype.format = String.prototype.f = function() {
      var s = this,
        i = arguments.length;

      while (i--) {
        s = s.replace(new RegExp("\\{" + i + "\\}", "gm"), arguments[i]);
      }
      return s;
    };
  },
  mounted: function() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(function(registration) {
        console.debug("A service worker is active:", registration.active); // eslint-disable-line no-console
      });
    } else {
      // TICKET-1476
      console.error("The navigator does not provide a 'service worker'."); // eslint-disable-line no-console
    }

    this.addDemoClass();
  }
};
</script>
