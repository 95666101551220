<template>
  <div v-if="showPanel" class="page">
    <div class="popup-overlay">
      <form class="popup">
        <p>{{ $t("termsAndConditions.updated") }}</p>
        <input
          @click="handleOk"
          class="addSuppEntryButton"
          type="button"
          value="OK"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "CheckTnC",
  data: function() {
    return {
      showPopup: true
    };
  },
  computed: {
    ...mapGetters("api", ["isOnline"]),
    ...mapState({
      userProfile: state => state.userProfile
    }),
    showPanel() {
      if (!this.isOnline) return false;
      if (!this.$route?.meta?.allowCheckTnC) return false;
      if (!this.userProfile) return false;
      if (!this.userProfile.termsAndConditionsAccepted) return this.showPopup;
      let acceptedTnC = new Date(this.userProfile.termsAndConditionsAccepted);
      let updateTnc = new Date(this.$const.TNC_UPDATE);
      return this.showPopup && acceptedTnC < updateTnc;
    }
  },
  methods: {
    handleOk() {
      this.showPopup = false;
      this.$router.replace({ name: "termsAndConditions" }).catch(error => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
