<template>
  <div id="pageLobby" class="page insideApp">
    <h1>{{ $t("lobby.title") }}</h1>

    <div class="content">
      <p v-if="!hasSubscriptions" class="emptyAccount">
        {{ $t("lobby.nomodule") }}
      </p>
      <p
        v-if="elogKeyIsNeeded"
        @click="elogClick"
        class="elogKeyIsNeeded topContentError topContentMessage"
      >
        {{ $t("message.no_elogkey") }}
      </p>
      <p
        v-if="elogKeyIsExpired"
        @click="elogClick"
        class="elogKeyIsExpired topContentWarning topContentMessage"
      >
        {{ $t("message.expired_elogkey") }}
      </p>
      <nav class="homeMenu">
        <div
          v-for="(subscription, index) in subscriptions"
          :key="subscription.uuid"
          class="module-btn-wrapper"
          :class="moduleClass(index)"
        >
          <div v-if="subscription.module.activated">
            <router-link
              class="renewOverlay"
              :to="{
                name: 'modulesorder',
                params: { id: subscription.module.id }
              }"
              v-if="subscription.willExpire || subscription.isExpired"
            >
              {{ $t("lobby.renew") }}
            </router-link>
            <router-link
              class="renewOverlay expiration willExpire"
              :to="{
                name: 'modulesorder',
                params: { id: subscription.module.id }
              }"
              v-if="subscription.willExpire && !subscription.isExpired"
            >
              {{ $t("lobby.expire") }}
              <br />{{ subscription.expiration | day }}
            </router-link>
            <router-link
              class="renewOverlay expiration alreadyExpired"
              :to="{
                name: 'modulesorder',
                params: { id: subscription.module.id }
              }"
              v-if="subscription.isExpired"
            >
              {{ $t("lobby.expired") }}
              <br />{{ subscription.expiration | day }}
            </router-link>
            <router-link
              class="module-link"
              :to="{ name: 'module', params: { uuid: subscription.uuid } }"
              :class="subscription.module.isDemo && 'demo'"
            ></router-link>
            <span
              class="icon"
              style="background-repeat: no-repeat; background-position: center;"
              :style="{
                'background-image':
                  'url(' + getImageData(subscription.module.icon) + ')'
              }"
            ></span>
            <span class="label">{{ subscription.module.label }}</span>
          </div>
        </div>
      </nav>

      <div class="btn-add-module">
        <router-link class="addModuleLink" :to="{ name: 'modulesadd' }">
          <span class="text">{{ $t("lobby.add") }}</span>
          <span class="plus-icon">+</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// pour l'instant les images sont importees dans lobby_icons au lieu de la technique ci-bas
// import logoExperimentalDataLobster from "@/assets/lobby-icons/experimentaldata-lobster.png";
// import logoFishing from "@/assets/lobby-icons/fishing.png";
// import logoHalibut from "@/assets/lobby-icons/halibut.png";
// import logoLifebuoy from "@/assets/lobby-icons/lifebuoy.png";
// import logoLobster from "@/assets/lobby-icons/lobster.png";
// import logoPelagicspecies from "@/assets/lobby-icons/pelagicspecies.png";
// import logoRockcrab from "@/assets/lobby-icons/rockcrab.png";
// import logoShrimp from "@/assets/lobby-icons/shrimp.png";
// import logoSnowcrab from "@/assets/lobby-icons/snowcrab.png";
// import logoTuna from "@/assets/lobby-icons/tuna.png";
import LOBBY_ICONS from "@/utils/lobby_icons";

export default {
  name: "modules",
  computed: {
    elogKeyIsNeeded() {
      const profile = this.$store.getters.USER_PROFILE;
      return profile && !profile.hasElogKey;
    },
    elogKeyIsExpired() {
      const profile = this.$store.getters.USER_PROFILE;
      return profile && profile.isElogKeyExpired;
    },
    subscriptions() {
      return this.$store.getters.SUBSCRIPTIONS.filter(
        subscription => subscription.activated && subscription.module.activated
      ).sort(
        (subscription1, subscription2) =>
          subscription1.module.isDemo - subscription2.module.isDemo
      );
    },
    hasSubscriptions() {
      const subscriptions = this.$store.getters.SUBSCRIPTIONS;
      return subscriptions && subscriptions.length;
    },
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    }
  },
  data() {
    return {
      // "experimentaldata-lobster": logoExperimentalDataLobster,
      // fishing: logoFishing,
      // halibut: logoHalibut,
      // lifebuoy: logoLifebuoy,
      // lobster: logoLobster,
      // pelagicspecies: logoPelagicspecies,
      // rockcrab: logoRockcrab,
      // shrimp: logoShrimp,
      // snowcrab: logoSnowcrab,
      // tuna: logoTuna
    };
  },
  methods: {
    elogClick() {
      this.$router.push({ name: "userprofile", params: { tab: 3 } });
    },
    getImageData: function(name) {
      if (!name) return "";
      const language = this.$store.getters.LANGUAGE;
      return LOBBY_ICONS[name.format(language)];
    },
    moduleClass(index) {
      const subscription = this.subscriptions[index];
      if (subscription.willExpire && !subscription.isExpired) {
        return "aboutToExpired";
      } else if (subscription.isExpired) {
        return "expired";
      }
      return false;
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    }
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    if (to.name == "module") {
      const uuid = to.params.uuid;
      this.$store.dispatch("selectSubscription", uuid).then(() => next());
    } else {
      next();
    }
    // else if (
    //   !this.isConnectedOnline &&
    //   (to.name == "modulesadd" || to.name == "modulesorder")
    // ) {
    //   this.onlinePopupError();
    // }
  },
  beforeDestroy() {}
};
</script>
