<template>
  <div :id="$options.name" :name="$options.name">
    <h1>{{ $t("header.termsAndConditions") }}</h1>
    <h2>{{ $t("termsAndConditions.privacyNotice.title") }}</h2>
    <p v-html="$t('termsAndConditions.privacyNotice.content')" />
    <h2>{{ $t("termsAndConditions.harvesterAttestation.title") }}</h2>
    <p v-html="$t('termsAndConditions.harvesterAttestation.content')" />
    <h2>{{ $t("termsAndConditions.jobelTermsOfUse.title") }}</h2>
    <p v-html="$t('termsAndConditions.jobelTermsOfUse.content')" />
    <form v-if="needSaveTnCVersion" @submit.prevent="saveAndContinue">
      <b-container fluid>
        <b-form-row>
          <b-col cols="auto">
            <input
              v-model="tncAccepted"
              :disabled="!isOnline"
              type="checkbox"
              name="userConfirmationCheckbox"
            />
          </b-col>
          <b-col align-self="center" v-html="$t('termsOfUse.confirmation')">
          </b-col>
        </b-form-row>
      </b-container>
      <div id="buttonContainer">
        <button
          :disabled="!tncAccepted"
          id="closeStatement"
          name="closeStatement"
          type="submit"
        >
          {{ $store.getters.IS_AUTHENTICATED ? $t("save") : $t("next") }}
        </button>
      </div>
    </form>
    <div v-else class="tncRouter">
      <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
    </div>
    <div
      v-if="!needSaveTnCVersion && user?.termsAndConditionsAccepted"
      id="tncDates"
    >
      <b-row>
        <b-col sm="12" cols="12">
          <LabelView
            :floatLeft="true"
            :label="$t('termsAndConditions.accepted')"
            :value="formatDate(user.termsAndConditionsAccepted)"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" cols="12">
          <LabelView
            :floatLeft="true"
            :label="$t('termsAndConditions.updateDate')"
            :value="formatDate($const.TNC_UPDATE)"
            type="text"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import LabelView from "@/components/LabelView.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "TermsAndConditions",
  components: {
    LabelView
  },
  data: function() {
    return {
      user: {},
      tncAccepted: false
    };
  },
  computed: {
    ...mapGetters("api", ["isOnline"]),
    ...mapState({
      userProfile: state => state.userProfile
    }),
    needSaveTnCVersion() {
      if (!this.$store.getters.IS_AUTHENTICATED) return true;
      let acceptedTnC = new Date(this.user.termsAndConditionsAccepted);
      let updateTnc = new Date(this.$const.TNC_UPDATE);
      return acceptedTnC < updateTnc;
    }
  },
  methods: {
    ...mapActions(["saveUserProfile"]),
    formatDate(inputDate) {
      const date = new Date(inputDate).toLocaleDateString();
      return date;
    },
    refreshUser() {
      this.user = JSON.parse(JSON.stringify(this.userProfile));
    },
    saveAndContinue: function(e) {
      this.$nextTick(() => {
        if (this.$store.getters.IS_AUTHENTICATED) {
          this.user.termsAndConditionsAccepted = new Date().toISOString();
          this.saveUserProfile(this.user)
            .then(this.$router.replace({ name: "modules" }))
            .catch(error => {
              this.$alertUserApiError(error);
            });
        } else {
          this.$router
            .replace({
              name: "connexionsignupform"
            })
            .catch(error => {});
        }
      });
    }
  },
  mounted() {
    this.refreshUser();
  }
};
</script>
<style lang="scss" scoped>
div#tncDates {
  margin: 0.5rem;
  padding-top: 2rem;
}
div#TermsAndConditions {
  max-width: 1080px;
  margin: 0 auto;
  width: 95%;
  h1,
  h2 {
    font-size: 1.2em;
    margin: 1rem 0.25rem;
  }
  h1 {
    font-size: 1.4em;
  }
  h2 {
    position: sticky;
    top: 0;
    padding: 5px;
    background: #f6f6f6;
    z-index: 10;
  }
  div#buttonContainer {
    @media screen and (min-width: 514.9px) {
      display: grid;
      justify-content: center;
    }
  }
  button {
    margin-top: 1rem;
    text-transform: uppercase;
    @media screen and (max-width: 539.9px) {
      margin-right: 0.5rem;
    }
  }
  p {
    margin: 0.5rem 0.5rem;
  }
  form {
    padding: 1rem 0;
  }
}
.tncRouter {
  text-align: center;
  padding-top: 3rem;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.floatLeft {
  padding-left: 0;
}
</style>
