var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.speciesAtRiskStep.title2")))])])])]),_vm._l((_vm.form.interactions),function(mminteration,index){return _c('fieldset',{key:index,staticClass:"removeBottomPadding"},[_c('h4',{staticClass:"level1",attrs:{"id":'interactions' + index}},[_vm._v(" "+_vm._s(_vm.$t("editTrip.speciesAtRiskStep.mmInteraction"))+" "+_vm._s(index + 1)+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('mmiSuppSpeciesAtRiskEntry-' + index + '-remark'),expression:"'mmiSuppSpeciesAtRiskEntry-' + index + '-remark'"},{name:"show",rawName:"v-show",value:(mminteration['closeDatetime'] === undefined),expression:"mminteration['closeDatetime'] === undefined"}],staticClass:"add-remark-btn-title"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'mmiSuppSpeciesAtRiskEntry-' + index + '-remark'},model:{value:(mminteration['remark']),callback:function ($$v) {_vm.$set(mminteration, 'remark', $$v)},expression:"mminteration['remark']"}})],1),_c('b-overlay',{key:'specie' + index + mminteration['closeDatetime'],attrs:{"show":mminteration['closeDatetime'] !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+" "+_vm._s(_vm.$showDate(mminteration["closeDatetime"]))+" ")])]},proxy:true}],null,true)},[_c('BasicInfo',{attrs:{"addErrorToParent":error => {
            _vm.addError(error, index);
          },"parentShowError":_vm.showErrorData + _vm.refreshIndex[index],"mminteration":mminteration,"index":index}}),_c('Details',{attrs:{"addErrorToParent":error => {
            _vm.addError(error, index);
          },"parentShowError":_vm.showErrorData + _vm.refreshIndex[index],"mminteration":mminteration,"index":index}}),_c('Incident',{attrs:{"addErrorToParent":error => {
            _vm.addError(error, index);
          },"parentShowError":_vm.showErrorData + _vm.refreshIndex[index],"mminteration":mminteration,"index":index}}),_c('identification',{attrs:{"addErrorToParent":error => {
            _vm.addError(error, index);
          },"parentShowError":_vm.showErrorData + _vm.refreshIndex[index],"mminteration":mminteration,"index":index}})],1),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeBottomPadding"},[_c('p',[(
            !mminteration['closeDatetime'] &&
              mminteration.hasMmi !== _vm.$const.NO
          )?_c('input',{staticClass:"closeSuppEntryButton",class:index + 1 < _vm.form.interactions.length
              ? 'addBottomMargin'
              : 'removeBottomMargin',attrs:{"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.close') + String(index + 1)},on:{"click":function($event){return _vm.closeMmiEvent(index)}}}):_vm._e()])])],1)}),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeTopMargin"},[_c('p',[(
          _vm.form.interactions &&
            _vm.form.interactions.length > 1 &&
            _vm.form.interactions[0].hasMmi !== _vm.$const.NO &&
            !_vm.$isLastItemClosed(_vm.form.interactions)
        )?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.remove') +
            String(_vm.form.interactions.length)},on:{"click":function($event){return _vm.removeMmiEvent()}}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"href":'#interactions' + (_vm.form.interactions.length - 1),"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.add')},on:{"click":_vm.addMmiEvent}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }