<template>
  <p class="field-wrapper" :name="id">
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
      <a v-if="hasHelp" class="dot" :id="'tooltipButton' + id"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        :target="'tooltipButton' + id"
        :title="help"
        placement="topleft"
        triggers="click"
        variant="primary"
      >
      </b-tooltip>
    </span>
    <span class="datetimeWidget">
      <input
        v-model="date"
        :disabled="disabled"
        :class="{
          error: errorClass.error,
          date: !hideTime,
          dateHideTime: hideTime,
          removeAutodatetime: !autodatetime
        }"
        class="datetime"
        type="date"
        @blur="formatDatetime"
      />
      <input
        v-if="!hideTime"
        v-model="time"
        :class="{
          error: errorClass.error,
          removeAutodatetime: !autodatetime
        }"
        :disabled="disabled"
        @blur="formatDatetime"
        class="datetime time"
        type="time"
      />
      <input
        v-if="autodatetime"
        :disabled="disabled"
        @click="autodatetimeClick()"
        class="autodatetime"
        type="button"
      />
    </span>

    <!--Error-->
    <label v-if="errorMessage" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
// TODO: Est-ce que le code de validation devrait être dans la composante ?
import Validator from "@/utils/validator.js";
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "DateInput",
  props: {
    autodatetime: {
      type: Boolean,
      default: false
    },
    dateErrorMsg: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      type: [String, Boolean],
      default: false
    },
    help: {
      type: String,
      default: ""
    },
    hideTime: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    maxDates: {
      type: Array, // of Objects: { value: Date, text: String }
      default: function() {
        return [];
      },
      validator: function(value) {
        return true;
      }
    },
    minDates: {
      type: Array, // of Objects: { value: Date, text: String }
      default: function() {
        return [];
      },
      validator: function(value) {
        return true;
      }
    },
    passDate: {
      type: Boolean,
      default: false
    },
    refeshError: {
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      id: this.$options.name + "-" + (Math.random() * 100000).toFixed(0),
      date: "",
      defaultErrorMsg: "",
      errorClass: { error: false },
      hasHelp: !!this.help,
      isError: false,
      time: ""
    };
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile
    }),
    errorMessage() {
      if (this.forceErrorMsg) return this.forceErrorMsg;
      if (this.errorMsg) return this.errorMsg;
      return this.defaultErrorMsg;
    }
  },
  watch: {
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    refeshError() {
      this.refreshErrorMethod();
    },
    value() {
      if (this.value) {
        let datetime = DateTime.fromISO(this.value, { zone: this.getZone() })
          .toISO()
          .split("T");
        this.time = datetime[1].slice(0, -13);
        this.date = datetime[0];
      } else {
        this.time = "";
        this.date = "";
      }
    }
  },
  methods: {
    autodatetimeClick() {
      let datetime = DateTime.local().setZone(this.getZone());
      let components = datetime.toString().split("T");

      this.time = components[1].slice(0, -13);
      this.date = components[0];

      this.formatDatetime();
    },
    focusChanged(event) {
      this.setError(event.target.value);
      let value = event.target.value;
      this.$emit("binding", value);
    },
    formatDatetime() {
      let value = this.date + "T";
      value += this.time ? this.time : "00:00:00.000";
      value = DateTime.fromISO(value, { zone: this.getZone() })
        .toUTC()
        .toISO();
      const rt = Validator.genericValidation(value, {
        type: "datetime",
        required: this.required,
        minDates: this.minDates,
        maxDates: this.maxDates,
        hideTime: this.hideTime,
        passDate: this.passDate,
        dateErrorMsg: this.dateErrorMsg
      });
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.$emit("input", value);
    },
    getZone() {
      return this.userProfile.timezone;
    },
    onChange(value) {
      if (value && value === "") {
        value = null;
      }
      this.$emit("input", value);
    },
    setError(value) {
      const rt = Validator.genericValidation(value, {
        type: "datetime",
        required: this.required,
        minDates: this.minDates,
        maxDates: this.maxDates,
        hideTime: this.hideTime,
        passDate: this.passDate,
        dateErrorMsg: this.dateErrorMsg
      });
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    refreshErrorMethod() {
      this.setError(this.value);
    }
  },
  mounted() {
    if (this.value) {
      let datetime = DateTime.fromISO(this.value, { zone: this.getZone() })
        .toISO()
        .split("T");
      this.time = datetime[1].slice(0, -13);
      this.date = datetime[0];
      this.refreshErrorMethod();
    } else {
      this.time = "";
      this.date = "";
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
