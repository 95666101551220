import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import "./registerServiceWorker";
import i18n from "./i18n";
import localforage from "localforage";
import VueSwal from "vue-swal";
import VueMask from "v-mask";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

console.log("Running Jobel client version", process.env.VUE_APP_VERSION); // eslint-disable-line no-console

Vue.use(BootstrapVue);
Vue.use(VueSwal);
Vue.use(VueMask);
Vue.use(IconsPlugin);

import "bootstrap/dist/css/bootstrap.css";
import "@/styles/offlineapp/app.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

Vue.filter("time", function(date) {
  if (date && date instanceof Date) {
    var h = ("0" + date.getHours()).slice(-2);
    var m = ("0" + date.getMinutes()).slice(-2);
    return h + ":" + m;
  }
  return "";
});

Vue.filter("day", function(date) {
  if (date && date instanceof Date) {
    var y = date.getFullYear();
    var m = ("0" + (date.getMonth() + 1)).slice(-2);
    var d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
  } else if (typeof date === "string") {
    var converted = new Date(date);
    var y = converted.getFullYear();
    var m = ("0" + (converted.getMonth() + 1)).slice(-2);
    var d = ("0" + converted.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
  }
  return "";
});

Vue.prototype.$compareObjects = function(objA, objB) {
  const obj1Json = JSON.stringify(objA);
  const obj2Json = JSON.stringify(objB);
  return obj1Json === obj2Json;
};

Vue.prototype.$showDfoInstructions = function(formId) {
  const key = "triplist.dfoInstructions.documents." + formId;
  const document = this.$t(key);
  const url = "/DFO-Instructions/" + document;
  window.open(url);
};

// TODO: Enlever la dépendance sur userProfile
Vue.prototype.$showDate = function(
  stringDate,
  timeOptions = { timeStyle: "short" }
) {
  let date;
  if (stringDate) {
    date = new Date(stringDate);
  } else {
    date = new Date();
  }

  let options = timeOptions;
  options.timeZone = this.userProfile.timezone;
  options.dateStyle = "medium";

  return date.toLocaleString(this.$i18n.locale, options);
};

Vue.prototype.$showDateOnly = function(stringDate) {
  return this.$showDate(stringDate, {});
};

/**
 * This function is used to show or hide Remove Button.
 * We cannot enable the remove button if the last item is closed
 * @param {*} data
 * @returns
 */
Vue.prototype.$isLastItemClosed = function(data) {
  if (!Array.isArray(data)) {
    return data?.closeDatetime != null;
  }
  if (Array.isArray(data) && data.length === 0) return false;
  const lastItem = data[data.length - 1];
  if (lastItem && lastItem.closeDatetime) {
    return true;
  }
  return false;
};

Vue.prototype.$makeAlphaId = function(theLen) {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (var i = 0; i < theLen; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

Vue.prototype.$const = {
  TNC_UPDATE: Date.UTC(2025, 0, 13, 0, 0, 0),
  NO: 11619,
  YES: 11617,
  LOBSTER: 1312,
  SPECIE_OTHER: 814,
  FORM_ROUND: 4691,
  FORM_DRESSED: 4645,
  LIC_NO_MAX_LENGTH: process.env.VUE_APP_LIC_NO_MAX
    ? parseInt(process.env.VUE_APP_LIC_NO_MAX)
    : 6,
  INACTIVITYID: "inactivity",
  BLOCKED: 0,
  OPTIONAL: 1,
  MANDATORY: 2,
  VRN_MAX_LENGTH: process.env.VUE_APP_VRN_MAX
    ? parseInt(process.env.VUE_APP_VRN_MAX)
    : 6,
  NO_VESSEL_NUMBER: 999999,
  NO_VESSEL_NAME: "not using a boat",
  GEAR_INFO_SHRIMP: 1,
  GEAR_INFO_CRAB: 2,
  CATCH_DETAIL_MODE_PRAWN: 1,
  CATCH_DETAIL_MODE_ATLANTIC_STURGEON: 2,
  BLUEFINTUNA_SPC: 19055,
  BODY_LEN_CAT_OTHER: 39620
};

/**
 * Check if there is a error reported by input components and call scrollIntoView on the top most
 *
 * @param {string} errorObjectName defaults to error, it will look in this[errorObjectName] for reported errors
 * @returns {boolean} true if there is an error; false otherwise.
 */
Vue.prototype.$hasInputErrorAndScroll = function(errorObjectName = "error") {
  if (!this[errorObjectName]) {
    // eslint-disable-next-line no-console
    console.warn(
      `You need a '${errorObjectName}' property on your component before using hasInputErrorAndScroll`
    );
    return true;
  }

  var top;
  var hasError = false;
  var elName;
  const keys = Object.keys(this[errorObjectName]);
  for (let i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (this[errorObjectName][key]) {
      hasError = true;
      var list = document.getElementsByName(key);
      if (list.length > 0) {
        var el = list[0];
        var y = el.getBoundingClientRect().y;
        if (!top || y < top) {
          top = y;
          elName = key;
        }
      }
    }
  }
  if (top) {
    document.getElementsByName(elName)[0].parentElement.scrollIntoView();
  }
  return hasError;
};

/**
 * Check if there is a error reported by input components and call scrollIntoView on the top most
 *
 * @param {string} errorObjectName defaults to error, it will look in this[errorObjectName] for reported errors
 * @returns {boolean} true if there is an error; false otherwise.
 */
Vue.prototype.$hasInputErrorAndScrollSubform = function(
  errorObjectName = "error",
  pages = []
) {
  if (!this[errorObjectName]) {
    // eslint-disable-next-line no-console
    console.warn(
      `You need a '${errorObjectName}' property on your component before using hasInputErrorAndScroll`
    );
    return true;
  }

  var top;
  var hasError = false;
  var elName;

  pages.forEach(page => {
    if (page in this[errorObjectName]) {
      const keys = Object.keys(this[errorObjectName][page]);
      for (let i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (this[errorObjectName][page][key]) {
          hasError = true;
          var list = document.getElementsByName(key);
          if (list.length > 0) {
            var el = list[0];
            var y = el.getBoundingClientRect().y;
            if (!top || y < top) {
              top = y;
              elName = key;
            }
          }
        }
      }
    }
  });
  if (top) {
    document.getElementsByName(elName)[0].parentElement.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center"
    });
  }
  return hasError;
};

Vue.prototype.$scrollToTopElements = function(elements) {
  var top;
  var hasError = false;
  var elName;

  for (let element in elements) {
    var list = document.getElementsByName(element);
    if (list.length > 0) {
      var el = list[0];
      var y = el.getBoundingClientRect().y;
      if (!top || y < top) {
        top = y;
        elName = element;
      }
    }
  }
  if (top) {
    document.getElementsByName(elName)[0].parentElement.scrollIntoView();
  }
};

/**
 * Try to extract a message to show the user from the error object received from Django
 * @param {*} error
 */
Vue.prototype.$alertUserApiError = function(error) {
  let message;
  if (error.hasOwnProperty("i18n")) {
    // handled in the client already, key to traduced messages
    message = this.$i18n.t(error.i18n);
  } else if (error.hasOwnProperty("message")) {
    // message is usually manually set for certain cases, should be prioritary
    message = error.message;
  } else if (error.hasOwnProperty("errors")) {
    // errors, if present, contains ModelForm complaints
    const keys = Object.keys(error.errors);
    let messages = [];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = error.errors[key][0];
      messages.push(`[${key}]: ${value}`);
    }
    message = messages.join("\n\n");
  }

  if (message?.length > 0) {
    // text can be long, using swal directly
    this.$swal({
      title: this.$t("error"),
      text: message,
      icon: "error",
      button: "OK"
    });
  }
};

const checkAppLock = function() {
  return localforage
    .getItem("applocked")
    .then(function(isLocked) {
      if (isLocked) store.commit("SET_LOCK", true);
      return Promise.resolve(true);
    })
    .catch(error => {
      console.error("while checking for lock", error); // eslint-disable-line no-console
    });
};

localforage
  .getItem("locale")
  .then(function(locale) {
    if (!locale) throw new Error("no locale preference found");
    return store.dispatch("setLanguage", locale);
  })
  .catch(error => {
    localforage.setItem("locale", i18n.locale);
  })
  .then(checkAppLock)
  .finally(function() {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount("#app");

    Vue.prototype.$navigator = window.navigator;
  });
