<template>
  <div>
    <h3>
      <span>
        <span>
          {{ $t("editTrip.subscriptionStep.title") }}
        </span>
      </span>
    </h3>
    <fieldset>
      <h4>{{ $t("editTrip.subscriptionStep.identification") }}</h4>
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.logbookUID')"
            :value="currentTrip['logbookUID']"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.version')"
            :value="currentTrip.software['version']"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.provider')"
            :value="currentTrip.software['provider']"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.tripNumber')"
            :value="currentTrip['tripNumber']"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.creation')"
            :value="$showDate(currentTrip['creation'])"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.subform')"
            :value="currentTrip['subform']"
            type="select"
            :options="systemsLists.subforms"
          />
        </b-col>
        <b-col v-if="currentTrip['fleet']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.fleet')"
            :value="currentTrip['fleet']"
            :options="systemsLists.elogFleets"
            type="select"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.dfo')"
            :value="currentTrip['dfoRegion']"
            :options="systemsLists.dfoRegions"
            type="select"
          />
        </b-col>
      </b-row>
      <b-row v-if="currentTrip['remark']">
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="currentTrip['remark']"
            type="text"
          />
        </b-col>
      </b-row>
      <h4>
        {{ $t("editTrip.subscriptionStep.operator") }}
      </h4>
      <b-row>
        <b-col v-if="currentTrip.operator['name']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.operatorName')"
            :value="currentTrip.operator['name']"
            type="text"
          />
        </b-col>
        <b-col
          v-if="currentTrip.operator['fisherIdentificationNumber']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('editTrip.subscriptionStep.operatorFin')"
            :value="currentTrip.operator['fisherIdentificationNumber']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip.operator['email']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('fields.email')"
            :value="currentTrip.operator['email']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip.operator['address']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('fields.address')"
            :value="currentTrip.operator['address']"
            type="text"
          />
        </b-col>
        <b-col
          v-if="currentTrip.operator['phoneNumber']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('fields.phone')"
            :value="currentTrip.operator['phoneNumber']"
            type="text"
          />
        </b-col>
      </b-row>
      <CrewViewer
        v-if="!isOldModule"
        v-model="crew"
        :hasCrewCount="crew.number > 0"
      />
      <fieldset v-if="showVesselFields">
        <h4>
          {{ $t("editTrip.subscriptionStep.vessel") }}
        </h4>
        <b-row>
          <b-col v-if="showVesselName" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.subscriptionStep.vesselName')"
              :value="currentTrip.vessel['name']"
              type="text"
            />
          </b-col>
          <b-col v-if="showVesselNumber" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.subscriptionStep.vesselNumber')"
              :value="currentTrip.vessel['registeredNumber']"
              type="text"
            />
          </b-col>
        </b-row>
      </fieldset>
      <b-row>
        <b-col v-if="currentTrip['crewNb']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('fields.crewNb')"
            :value="currentTrip['crewNb']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip['observerOnBoard']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.observerOnBoard')"
            :value="currentTrip['observerOnBoard']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="currentTrip['observerId']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.observerId')"
            :value="currentTrip['observerId']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip['observerName']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.observerName')"
            :value="currentTrip['observerName']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip['observerTripNumber']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.observer')"
            :value="currentTrip['observerTripNumber']"
            type="text"
          />
        </b-col>
        <b-col v-if="currentTrip['partnership']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.partnership')"
            :value="currentTrip['partnership']"
            :options="$t('mv_table.partnership')"
            type="select"
          />
        </b-col>
        <b-col v-if="currentTrip['useCarrier']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('carrier.useIndicator')"
            :value="currentTrip['useCarrier']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="currentTrip['hasFished']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.subscriptionStep.hasFished')"
            :value="currentTrip['hasFished']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="currentTrip['isCarrier']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('carrier.isIndicator')"
            :value="currentTrip['isCarrier']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
      </b-row>
    </fieldset>
    <br />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CrewViewer from "@/components/viewtrip/CrewViewer.vue";
import LabelView from "@/components/LabelView.vue";

export default {
  name: "SubscriptionViewer",
  components: {
    CrewViewer,
    LabelView
  },
  data: () => ({
    crew: {
      count: 0,
      members: []
    }
  }),
  computed: {
    ...mapGetters(["isOldModule"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile // needed by $showdate
    }),
    showVesselName() {
      return (
        this.currentTrip.vessel["name"] &&
        this.currentTrip.vessel["name"] !== this.$const.NO_VESSEL_NAME
      );
    },
    showVesselNumber() {
      return (
        this.currentTrip.vessel["registeredNumber"] &&
        this.currentTrip.vessel["registeredNumber"] !==
          this.$const.NO_VESSEL_NUMBER
      );
    },
    showVesselFields() {
      return this.showVesselNumber || this.showVesselName;
    }
  },
  watch: {
    systemsLists() {
      // @FIXME: Il faudra le changé lorsqu'il n'y aura plus d'option dans data
      this.$router.go();
    }
  },
  methods: {
    ...mapActions(["updateStateGeneric"]),
    initCrew() {
      this.crew.count = this.currentTrip.crewNb;
      this.crew.members =
        "crew" in this.currentTrip ? this.currentTrip.crew : [];
    }
  },
  mounted() {
    this.initCrew();
  },
  created() {}
};
</script>
