<template>
  <div>
    <fieldset class="fieldsetViewTrip">
      <h4 class="level1 viewStep">
        {{ $t("editTrip.gearLossStep.loss") }}&nbsp;{{ index + 1 }}
      </h4>
      <b-row>
        <b-col
          v-if="gearLoss['occursAt'] && gearLoss['occursAt']['datetime']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('editTrip.gearLossStep.lostDate')"
            :value="$showDateOnly(gearLoss['occursAt']['datetime'])"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="gearLoss.occursAt && gearLoss.occursAt['latitude']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('latitude')"
            :value="
              convertDD2toDMS(gearLoss.occursAt['latitude'], 'lat') +
                ' = ' +
                gearLoss.occursAt['latitude'] +
                '°'
            "
            type="text"
          />
        </b-col>
        <b-col
          v-if="gearLoss.occursAt && gearLoss.occursAt['longitude']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('longitude')"
            :value="
              convertDD2toDMS(gearLoss.occursAt['longitude'], 'lon') +
                ' = ' +
                gearLoss.occursAt['longitude'] +
                '°'
            "
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="gearLoss['gearType']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.gearLossStep.gearDesc')"
            :value="gearLoss['gearType']"
            :options="gearTypes"
            type="select"
          />
        </b-col>
        <b-col v-if="gearLoss['nbGear']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.gearLossStep.nbGearOld')"
            :value="gearLoss['nbGear']"
            type="text"
          />
        </b-col>
        <b-col v-if="gearLoss['tags']" lg="4" sm="6" cols="12">
          <LabelView
            v-if="gearLoss['tags'].length > 0"
            :label="$t('editTrip.gearLossStep.tags')"
            :value="gearLoss['tags']"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row v-if="gearLoss['remark']">
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remark')"
            :value="gearLoss['remark']"
            type="text"
          />
        </b-col>
      </b-row>
    </fieldset>
  </div>
</template>
<script>
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";

export default {
  name: "GearLostOldViewer",
  components: {
    LabelView
  },
  props: ["gearLoss", "index", "gearTypes"],
  data: () => ({
    msg: ""
  }),
  watch: {},
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  },
  mounted() {}
};
</script>
