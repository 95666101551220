<template>
  <fieldset name="Licences">
    <h4 class="level1">{{ $t("section.licences") }}</h4>
    <FishingLicence
      v-for="(licence, index) in form.licences"
      v-model="form.licences[index]"
      :refreshForm="refreshForm"
      :licences="form.licences"
      :key="'licence-' + index + '-of-' + form.licences.length"
      :licence="licence"
      :index="index"
      :multi="form.licences.length > 1"
      :subform="form.subform"
      :dfoRegion="form.dfoRegion"
      :refeshError="refreshError"
      @licenceError="checkError"
    />
    <fieldset class="suppEntryButtonWrapper">
      <p>
        <input
          v-if="form.licences.length > 1"
          class="removeSuppEntryButton"
          @click="removeLicences"
          type="button"
          :value="$t('settings.removeLicence')"
        />
      </p>
    </fieldset>
  </fieldset>
</template>
<script>
import FishingLicence from "@/components/FishingLicence.vue";

export default {
  name: "Licences",
  components: {
    FishingLicence
  },
  props: {
    refreshError: Number,
    form: Object,
    refreshForm: Function,
    setLicenceError: Function
  },
  data() {
    return {
      licenceError: {}
    };
  },
  methods: {
    checkError(value) {
      this.licenceError = { ...this.licenceError, ...value };
      this.setLicenceError(value);
    },
    removeLicences() {
      this.form.licences = this.form.licences.slice(0, -1);
      const vm = this;
      vm.refreshForm();
      vm.$nextTick(() => {
        if (vm.$hasInputErrorAndScroll("licenceError")) {
          return;
        }
        // TODO: Call action to save settings, when we develop offline settings
      });
    }
  }
};
</script>
